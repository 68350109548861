<template>
  <div class="viewer" :class="{'sidebar-hidden': !showSidebar, 'dictionary-hidden': !showDictionary}">
    <transition name="sidebar-slide">
      <div class="viewer-sidebar" v-if="showSidebar" :class="sidebarClasses" @click="sidebarClick" @scroll="onScroll">
        <div class="viewer-sidebar-content">
          <div v-if="showSidebarDetails" class="details-info-container">
            <slot name="sidebar-details" :setShowSidebar="setShowSidebarDetails"></slot>
          </div>
          <div v-else-if="showSidebarNavigation" class="viewer-sidebar-nav">
            <div class="heading">Navigation</div>
            <close-button @click="setShowSidebarNavigation(false)" />
            <slot name="sidebar-thumbnails"></slot>
          </div>
          <div v-else-if="showSidebarSearchResults" class="details-info-container">
            <slot
              name="sidebar-search-results"
              :setShowSidebar="setShowSidebarSearchResults"
              :setNormalSidebarWidth="setNormalSidebarWidth"
            ></slot>
          </div>
        </div>
      </div>
    </transition>
    <div class="viewport-wrapper" :class="viewportClasses">
      <slot name="viewport-top"></slot>
      <slot name="preview-unavailable" />
      <div class="document-viewports">
        <div class="viewport" :class="{'is-half-display': canShowDescriptionPanel}">
          <h4 class="no-images-label" v-if="showNoImages">Image Unavailable</h4>
          <mcr-loading-indicator v-else-if="imageLoading" :loading="true"></mcr-loading-indicator>
          <template v-else>
            <image-with-highlighted-matches-overlay
              :ref="imageWithMatchesRef"
              :image-src="src"
              :highlight-vertices="highlightVertices"
              :hoverable-vertices="hoverableVertices"
              :bordered-vertices="borderedVertices"
              :selected-vertices="focusedVertices"
              :disable-pan="!page.url"
              :disable-zoom="!page.url"
              :rotate="rotate"
              :allow-area-ocr="allowAreaOcr"
              @transform="onImageTransform"
              @box-click="onBoxClick"
              @box-hover="onBoxHover"
              @box-hover-end="onBoxHoverEnd"
              @panstart="onPanStart"
              @panend="onPanEnd"
              @image-loaded="onImageLoad"
            ></image-with-highlighted-matches-overlay>
          </template>
        </div>
        <div class="description-viewport" :class="{'is-hidden': !canShowDescriptionPanel}">
          <viewer-ocr-panel
            v-if="canShowOcrPanel"
            :ref="ocrPanelRef"
            :symbols-loading="loadingOcr"
            :ocr-symbols="ocrSymbols"
            :active-box-index="hoveredBoxIndex"
            :image-src="src"
            :highlight-all="highlightAll"
            @mounted="onOcrPanelMounted"
            @click-highlight-all="highlightAllClick"
          />
          <slot name="description-panel" v-if="showCustomDescriptionPanel"></slot>
        </div>
      </div>
      <transition name="dictionary-slide">
        <viewer-dictionary
          v-if="showDictionary"
          :words="dictionaryWords"
          :loading="isDictWordLoading"
          @close="toggleDictionary"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import ImageWithHighlightedMatchesOverlay from '@common/elements/layouts/book-viewer/ImageWithHighlightedMatchesOverlay';
import ViewerDictionary from '@common/elements/layouts/book-viewer/ViewerDictionary';
import ViewerOcrPanel from '@common/elements/layouts/book-viewer/ViewerOcrPanel';
import {descriptionChoices} from '@common/elements/layouts/book-viewer/constants';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {isChineseText} from '@common/utils/utils';
import {preload} from '@common/utils/utils.sources';
import {mapGetters} from 'vuex';

export default {
  props: {
    page: Object,
    pageOcr: Object,
    loadingPage: Boolean,
    loadingOcr: Boolean,
    showNoImages: Boolean,
    userHasFullAccess: Boolean,
    searchValue: String,
    initShowDict: Boolean,
    rotate: Number,
  },
  data() {
    return {
      showSidebarDetails: false,
      showSidebarSearchResults: false,
      showSidebarNavigation: false,
      showDictionary: false,
      disableDictionary: false,
      showOcrPanel: false,
      showCustomDescriptionPanel: false,
      wideSidebar: false,
      imageWithMatchesRef: 'image-with-matches',
      ocrPanelRef: 'ocr-panel',
      hoveredBoxIndex: null,
      focusedBoxIndex: null,
      highlightAll: false,
      trackedDictUsage: false, // track dict usage once per book
      imageChangedLoading: false,
    };
  },
  beforeMount() {
    this.setWideSidebarBySearch();
    this.setShowDictionary(!this.isMobileView && this.initShowDict);
    this.setShowSidebarSearchResults(this.$route.query.search);
    this.setShowSidebarNavigation(!this.isMobileView && !this.$route.query.search);
  },
  destroyed() {
    this.$store.commit('setBodyForbidScrollState', false);
  },
  watch: {
    searchValue(newValue) {
      this.setWideSidebarBySearch();
    },
    src(newValue, oldValue) {
      this.hoveredBoxIndex = null;
      this.focusedBoxIndex = null;
      if (this.page && !this.page.url) {
        this.setShowDictionary(false);
      }
      /* force image component to re-render on image change to avoid showing old image when new is loaded from browser cache*/
      this.imageChangedLoading = true;
      this.$nextTick(() => {
        this.imageChangedLoading = false;
      });
    },
    showSidebar(isShown) {
      if (this.sidebarFullViewportWidth) {
        this.$store.commit('setBodyForbidScrollState', isShown);
      }
    },
  },
  computed: {
    ...mapGetters([
      'dictionaryWordsState',
      'dictionaryWordsLoadingState',
      'dictionaryChineseWordsSetState',
      'windowWidthState',
    ]),
    allowAreaOcr() {
      if (this.isMobileView) {
        return this.showOcrPanel;
      }
      return this.userHasFullAccess;
    },
    highlightVertices() {
      return this.searchValue ? this.page.matches_vertices || [] : [];
    },
    hoverableVertices() {
      return this.ocrSymbols.map(i => i.boundingBox.vertices);
    },
    borderedVertices() {
      return this.highlightAll ? this.hoverableVertices : [];
    },
    focusedVertices() {
      if (this.isMobileView) {
        // disable dictionary functionality on mobile
        return [];
      }
      const ocrSymbol = this.ocrSymbols[this.focusedBoxIndex];
      return ocrSymbol ? [ocrSymbol.boundingBox.vertices] : [];
    },
    imageLoading() {
      return this.imageChangedLoading || !this.page || this.loadingPage || !this.src;
    },
    src() {
      const src = this.page && (this.page.url || this.page.previewSrc);
      if (src && src.startsWith('/media')) {
        return `${process.env.VUE_APP_MEDIA_BASE}${src}`;
      }
      return src;
    },
    displayNonOwnerSearchResults() {
      return this.searchValue && !this.userHasFullAccess;
    },
    viewportClasses() {
      return {'is-reduced': this.wideSidebar, 'is-enlarged': this.smallSidebar};
    },
    sidebarClasses() {
      return {
        'is-wide': this.wideSidebar,
        'is-small': this.smallSidebar,
        'full-viewport-width': this.sidebarFullViewportWidth,
      };
    },
    showSidebar() {
      return this.showSidebarDetails || this.showSidebarSearchResults || this.showSidebarNavigation;
    },
    smallSidebar() {
      if (this.sidebarFullViewportWidth || this.showSidebarDetails) {
        return false;
      }
      return this.showSidebarNavigation;
    },
    isMobileView() {
      return this.windowWidthState <= this.$breakpoints.tablet;
    },
    sidebarFullViewportWidth() {
      return this.isMobileView;
    },
    canShowOcrPanel() {
      return this.showOcrPanel && this.pageOcr;
    },
    canShowDescriptionPanel() {
      return this.canShowOcrPanel || this.showCustomDescriptionPanel;
    },
    ocrSymbols() {
      return this.pageOcr.ocr_symbols || [];
    },
    dictionaryText() {
      return this.hoveredText || this.focusedText;
    },
    dictionaryWords() {
      const dictText = this.dictionaryText;
      if (!dictText) {
        return [];
      }
      const dictionaryWords = this.dictionaryWordsState.filter(word => {
        return word.traditional === dictText || word.simplified === dictText;
      });
      return dictionaryWords.length ? dictionaryWords : [{simplified: dictText}];
    },
    hoveredText() {
      const ocrSymbol = this.ocrSymbols[this.hoveredBoxIndex];
      return ocrSymbol ? ocrSymbol.text : null;
    },
    focusedText() {
      const ocrSymbol = this.ocrSymbols[this.focusedBoxIndex];
      return ocrSymbol ? ocrSymbol.text : null;
    },
    isDictWordLoading() {
      return this.dictionaryText ? this.dictionaryWordsLoadingState[this.dictionaryText] : false;
    },
  },
  methods: {
    onScroll(e) {
      let component = this.$slots['sidebar-thumbnails'][0].componentInstance;
      if (component && component.updateVisibleOnScroll) {
        component.updateVisibleOnScroll(e);
      }
    },
    toggleDictionary() {
      this.setShowDictionary(!this.showDictionary);
    },
    toggleDescriptionPanel(item) {
      // used by ViewerPage
      const byItem = {
        [descriptionChoices.OCR]: () => {
          this.setShowOcrPanel(!this.showOcrPanel);
          this.setShowCustomDescriptionPanel(false);
        },
        [descriptionChoices.CUSTOM]: () => {
          this.setShowCustomDescriptionPanel(!this.showCustomDescriptionPanel);
          this.setShowOcrPanel(false);
        },
      };
      byItem[item]();
    },
    toggleShowSidebarDetails() {
      this.setShowSidebarDetails(!this.showSidebarDetails);
    },
    toggleSidebarNavigation() {
      this.setShowSidebarNavigation(!this.showSidebarNavigation);
    },
    setShowDictionary(show) {
      if (show && this.isMobileView) {
        return; // disable dictionary functionality on mobile
      }
      if (this.showDictionary === show) {
        return;
      }
      this.showDictionary = show;
      if (!this.showDictionary) {
        this.focusedBoxIndex = null;
      }
      this.redrawImageRelated(500);
    },
    setShowOcrPanel(show) {
      this.$emit('change-show-ocr-panel', show);
      this.showOcrPanel = show;
      this.redrawImageRelated(500);
      if (!show && this.$refs[this.imageWithMatchesRef]) {
        this.$refs[this.imageWithMatchesRef].setModeAreaSelection(false);
      }
    },
    setShowCustomDescriptionPanel(show) {
      this.$emit('change-show-description-panel', show);
      this.showCustomDescriptionPanel = show;
    },
    setShowSidebarDetails(show) {
      this.$emit('change-show-sidebar-details', show);
      this.showSidebarDetails = show;
      if (show && this.showSidebarNavigation) {
        this.setShowSidebarNavigation(false);
      }
      this.redrawImageRelated(400);
    },
    setShowSidebarNavigation(show) {
      this.$emit('change-show-sidebar-navigation', show);
      this.showSidebarNavigation = show;
      if (show && this.showSidebarDetails) {
        this.setShowSidebarDetails(false);
      }
    },
    setShowSidebarSearchResults(show) {
      this.showSidebarSearchResults = show;
    },
    setWideSidebar(isWide) {
      if (this.wideSidebar !== isWide) {
        this.wideSidebar = isWide;
        this.redrawImageRelated(500);
      }
    },
    setNormalSidebarWidth() {
      if (this.sidebarFullViewportWidth) {
        this.setShowSidebarSearchResults(false);
        this.setShowSidebarDetails(false);
        this.setShowSidebarNavigation(false);
      } else {
        this.setWideSidebar(false);
      }
    },
    sidebarClick() {
      this.setWideSidebarBySearch();
    },
    setWideSidebarBySearch() {
      if (!this.sidebarFullViewportWidth) {
        this.setWideSidebar(this.displayNonOwnerSearchResults);
      }
    },
    callOnImageWithMatches(callback) {
      if (this.$refs[this.imageWithMatchesRef]) {
        return callback(this.$refs[this.imageWithMatchesRef]);
      }
    },
    recalculateOcrScale() {
      if (this.canShowOcrPanel && this.$refs[this.ocrPanelRef]) {
        this.$refs[this.ocrPanelRef].recalculateScale();
      }
    },
    onImageTransform() {
      if (this.canShowOcrPanel && this.$refs[this.ocrPanelRef] && this.$refs[this.imageWithMatchesRef]) {
        this.$refs[this.ocrPanelRef].$refs.transformable.style.transform =
          this.$refs[this.imageWithMatchesRef].$refs.transformable.style.transform;
        this.$refs[this.ocrPanelRef].$refs.transformable.style['transform-origin'] =
          this.$refs[this.imageWithMatchesRef].$refs.transformable.style['transform-origin'];
      }
    },
    redrawImageRelated(animationTime) {
      this.callOnImageWithMatches(vm => vm.setImageHighlight(false));

      setTimeout(() => {
        // for animation to finish
        this.callOnImageWithMatches(vm => vm.onImageLoad());
        this.recalculateOcrScale();
        this.onImageTransform();
      }, animationTime);
    },
    onBoxClick(index) {
      const newIndex = this.focusedBoxIndex === index ? null : index;
      this.focusedBoxIndex = newIndex;
      this.setShowDictionary(true);
      this.onBoxHover(newIndex);
    },
    onBoxHover(index) {
      if (this.disableDictionary) {
        return;
      }
      this.hoveredBoxIndex = index;
      if (
        this.showDictionary &&
        this.hoveredText &&
        isChineseText(this.hoveredText, 100) &&
        !this.dictionaryChineseWordsSetState.has(this.hoveredText) &&
        !this.dictionaryWordsLoadingState[this.hoveredText]
      ) {
        this.$store.dispatch('findDictionaryWordAction', this.hoveredText);
        if (!this.trackedDictUsage) {
          this.trackedDictUsage = true;
          AnalyticsMainHandler.trackViewerUseDictionary();
        }
      }
    },
    onBoxHoverEnd(index) {
      if (this.disableDictionary) {
        return;
      }
      this.hoveredBoxIndex = null;
    },
    highlightAllClick() {
      this.highlightAll = !this.highlightAll;
    },
    onOcrPanelMounted() {
      this.redrawImageRelated(100);
    },
    onPanStart() {
      this.disableDictionary = true;
    },
    onPanEnd() {
      this.disableDictionary = false;
    },
    onImageLoad() {
      if (this.page.preload_urls && this.page.preload_urls.length) {
        preload(this.page.preload_urls, 0);
      }
    },
  },
  components: {
    ViewerDictionary,
    ImageWithHighlightedMatchesOverlay,
    ViewerOcrPanel,
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width: 340px;
$sidebar-small-width: 200px;
$sidebar-wide-width: 700px;
$dictionary-height: 200px;

.viewer {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  height: 0; // height is required for firefox to apply overflow: hidden

  > * {
    transition: all 0.4s ease;
  }

  .viewer-sidebar {
    width: $sidebar-width;
    background-color: $background-color;
    border-top: 1px solid $divider-line-color;
    overflow-y: auto;
    position: relative;
    z-index: 2;
    box-shadow: 12px 12px 15px rgba(black, 0.05);
    .viewer-sidebar-content {
      overflow: hidden;
      .details-info-container {
        padding: 24px;
        position: relative;
        .access-button {
          margin-bottom: 20px;
        }
      }
    }

    &.is-wide {
      width: $sidebar-wide-width;
    }

    &.is-small {
      width: $sidebar-small-width;
    }

    &::v-deep {
      .close-btn {
        position: absolute;
        top: -18px;
        right: -3px;
        padding: 0;
        z-index: 5;
      }
      h5 {
        border-bottom: 1px solid $divider-line-color;
        margin-bottom: 24px;
        padding-bottom: 12px;
        color: $text-alternate-color;
        font-family: $default-font;
        font-weight: normal;
      }
    }

    &.full-viewport-width {
      width: 100vw;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 101;
      height: 100vh;
      min-height: 100vh;
      overflow-y: auto;
      &.is-wide {
        width: 100vw;
      }
      .viewer-sidebar-content {
        padding-bottom: 100px;
      }
    }
  }

  .viewer-sidebar-nav {
    position: relative;
    .heading {
      color: $supplemental-text-color;
      font-size: 20px;
      padding: 15px 10px 0;
      margin-bottom: 10px;
    }
    .close-btn {
      top: -5px;
      right: 10px;
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      .close-btn {
        position: fixed;
      }
    }
  }

  .viewport-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    width: calc(100% - #{$sidebar-width});
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #222;
    overflow: hidden;

    &.is-reduced {
      width: calc(100% - #{$sidebar-wide-width});
    }
    &.is-enlarged {
      width: calc(100% - #{$sidebar-small-width});
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    .preview-unavailable {
      z-index: 2;
    }

    .document-viewports {
      z-index: 1;
      height: calc(100% - #{$dictionary-height});
      width: 100%;
      flex-grow: 1;
      flex-shrink: 0;
      overflow: hidden;
      display: flex;
      transition: all 0.4s ease;

      .image-with-highlighted-matches-overlay,
      &::v-deep .vue-pan-zoom-item,
      &::v-deep .vue-pan-zoom-scene {
        display: flex;
        height: 100%;
        width: 100%;
      }

      .viewport {
        display: flex;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease;

        .no-images-label {
          color: $off-white;
          text-align: center;
        }

        .mcr-loading-indicator {
          opacity: 0.2;
        }
        .search-results-container {
          padding: 70px 20px 20px;
        }
      }

      .description-viewport {
        width: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        transition: all 0.4s ease;
        overflow: hidden;
        background-color: rgba(white, 0.1);
        color: white;

        &.is-hidden {
          width: 0;
          opacity: 0;
        }
      }

      /* Swap OCR vs Original Image orientation on mobile portrait */
      @media only screen and (max-width: $breakpoint-mobile) and (orientation: portrait) {
        flex-direction: column;
        .viewport.is-half-display {
          height: 50%;
        }

        .description-viewport {
          height: 50%;
          width: 100%;

          &.is-hidden {
            width: 100%;
            height: 0;
            opacity: 0;
          }
        }
      }
    }

    .viewer-dictionary {
      height: $dictionary-height;
    }
  }

  /* Settings "pushed" elements for smoother transitions on module toggles */
  &.sidebar-hidden {
    .viewport-wrapper {
      width: 100%;
    }
  }

  &.dictionary-hidden {
    .viewport-wrapper {
      .document-viewports {
        height: 100%;
      }
    }
  }
}

/* Transitions */
.sidebar-slide-enter-active,
.sidebar-slide-leave-active {
  transition: all 0.4s ease;
}
.sidebar-slide-enter,
.sidebar-slide-leave-to {
  transform: translateX(calc(0px - #{$sidebar-width}));
  opacity: 0;
}

.dictionary-slide-enter-active,
.dictionary-slide-leave-active {
  transition: all 0.4s ease;
}
.dictionary-slide-enter,
.dictionary-slide-leave-to {
  opacity: 0;
  transform: translateY(calc(0px + #{$dictionary-height}));
}
</style>
