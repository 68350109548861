<template>
  <div class="search-results-container">
    <h5>Search Results</h5>
    <close-button class="desktop-only" @click="closeClick" />
    <div class="search-controls mobile-only">
      <icon-input
        class="search-control"
        :value="searchValue"
        @change="onChange"
        type="search"
        placeholder="Search this document..."
        @blur="onSearch()"
        @keyup.enter="onSearch()"
      >
        <search-icon class="search-icon" :size="26" />
      </icon-input>
      <a @click="closeClick">Cancel</a>
    </div>
    <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
    <div class="search-results-items" v-else>
      <base-pagination-meta v-if="results.length" :meta="resultsMeta"></base-pagination-meta>
      <base-pagination :meta="resultsMeta" :show-jump-to="false" @onSwitchPage="onSwitchPage"></base-pagination>
      <search-results
        v-if="results.length"
        :search-query="searchValue"
        :results="results"
        :results-meta="resultsMeta"
        @click="onResultClick"
      ></search-results>
      <search-no-results v-else-if="value" :search-query="searchValue"></search-no-results>
      <base-pagination :meta="resultsMeta" :show-jump-to="false" @onSwitchPage="onSwitchPage"></base-pagination>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import BasePaginationMeta from '@common/elements/BasePaginationMeta.vue';
import CloseButton from '@common/elements/buttons/closeButton';
import IconInput from '@common/elements/inputs/IconInput';
import SearchNoResults from '@common/pages/viewer/SearchNoResults';
import SearchResults from '@common/pages/viewer/SearchResults';
import SearchIcon from 'vue-material-design-icons/Magnify';

export default {
  props: {
    value: String,
    loading: Boolean,
    results: Array,
    resultsMeta: Object,
  },
  data() {
    return {
      searchValue: this.value,
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.searchValue = newValue;
    },
  },
  methods: {
    onChange(event) {
      this.searchValue = event.target.value;
    },
    onResultClick(pageNumber) {
      this.$emit('search-result-click', pageNumber);
    },
    onSwitchPage(page) {
      this.onSearch(page);
    },
    onSearch(page) {
      this.$emit('search', {value: this.searchValue, page: page || 1});
    },
    closeClick() {
      this.$emit('close');
    },
  },
  components: {SearchNoResults, CloseButton, SearchIcon, IconInput, BasePagination, BasePaginationMeta, SearchResults},
  name: 'SourceSearchResultsContainer',
};
</script>

<style lang="scss" scoped>
.search-results-container {
  position: relative;
  width: 100%;

  .search-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .search-control {
      flex-grow: 1;
      margin-right: 16px;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .mobile-only {
      display: flex;
    }
    .desktop-only {
      display: none;
    }
  }
  @media only screen and (min-width: $breakpoint-tablet + 1) {
    .mobile-only {
      display: none;
    }
    .desktop-only {
      display: block;
    }
  }
}
</style>
